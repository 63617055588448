import React from "react";
import NotificationsToast, { NotificationsToastList } from "@hig/notifications-toast";

function Notifications({ list = [], remove = ()=>{} }) { //list and remove assigned to default values in case they come in undefined

  return (
      <NotificationsToastList placement="bottom">
        {list.map((toast, i) => (<NotificationsToast status={toast.style} onDismiss={() => remove(i)}>
          <strong>{toast.title}</strong>
          {toast.description}
        </NotificationsToast>)
        )}
      </NotificationsToastList>
  );
}

export default Notifications;
