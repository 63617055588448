import React from "react";
import {
  superParser,
  htmlTagRegex,
  getAuthorAvatars,
} from "../utilities/datasetManager";
import parse from "html-react-parser";
// import Avatar, { sizes } from "@hig/avatar";

function DataCard({
  specialFormatting,
  data,
  searchString,
  nonCoreList,
  info,
}) {
  // for a "more/less" expander that we are no longer using
  // const [expand, setExpand] = useState(false); //default accordion closed
  // const toggleExpand = () => {
  //   setExpand(!expand);
  // };

  /**
   * html chunk for the title and subtitle of the data card
   */
  const getTitleAndSubtitleBlock = () => {
    return (
      <div className="headTitle">
        {title && Array.isArray(title) ? (
          title.map((_title, mapIndex) => (
            <div key={`DataCard-title-${_title.entry}-${mapIndex}}`} className="title">
              {superParser(_title.entry, searchString)}
            </div>
          ))
        ) : (
          <div className="title">{superParser(title, searchString)}</div>
        )}
        {subtitle && Array.isArray(subtitle) ? (
          subtitle.map((_subtitle, mapIndex) => (
            <div key={`DataCard-subtitle-${_subtitle.entry}-${mapIndex}}`} className="subtitle">
              {superParser(_subtitle.entry, searchString)}
            </div>
          ))
        ) : (
          <div className="subtitle">{superParser(subtitle, searchString)}</div>
        )}
      </div>
    );
  };

  //fields is an array
  //if empty entry aka valueOfFirstField has nothing, we're not showing it
  const mapFields = (fields) =>
    fields === undefined
      ? undefined
      : fields
          .filter(({ entry }) => entry !== "")
          // Use the header and index to compose unique key for the parent div
          .map(({ entry, subHeader }, index) => (
            <div
              key={`${subHeader}${index}`}
              className="mappedFields"
              style={
                // Subsequent authorFields should stack below the first one, left-aligned, with a small gap between them.
                // https://wiki.autodesk.com/pages/viewpage.action?spaceKey=~jensenl&title=Prism+-+Special+Formatting+and+Field+Blocks
                index > 0 &&
                specialFormatting["authorFields"] !== undefined &&
                ((typeof specialFormatting["authorFields"] === "string" &&
                  specialFormatting["authorFields"].toLowerCase() ===
                    subHeader.toLowerCase()) ||
                  (Array.isArray(specialFormatting["authorFields"]) &&
                    specialFormatting["authorFields"].some(
                      (authorField) =>
                        authorField.toLowerCase() === subHeader.toLowerCase()
                    )))
                  ? {
                      marginTop: 5,
                    }
                  : {}
              }
            >
              <div className="dataCardFieldName">{subHeader}</div>
              <div className="dataCardFieldValue">
                {superParser(
                  // PRISM-13 - If the field contains HTML markup, use the HTML parser to render it
                  // Also re-add commas if this is a list of authors with links
                  // https://wiki.autodesk.com/display/~jensenl/Prism+Use+Cases+-+Authors
                  parse(
                    htmlTagRegex.test(entry) &&
                      specialFormatting["authorFields"] === subHeader
                      ? entry.replace(/> </g, ">&#44; <")
                      : entry
                  ),
                  searchString
                )}
              </div>
            </div>
          ));

  // PRISM-8: fallback to most used field names
  // Title
  const title = data[specialFormatting["titleFields"]];
  // Subtitle
  const subtitle = data[specialFormatting["subtitleFields"]];
  // Timeframe
  const timeframe = mapFields(data[specialFormatting["dateFields"]]);
  // Author(s)
  const authors = data[specialFormatting["authorFields"]];
  const mappedAuthors = mapFields(authors);

  // This returns an array of strings corresponding to the avatars to show in this card.

  const avatarPaths = () => {
    // maxAvatarCount is the max number of avatars that we will show,
    // including pictures, blanks, and the overflow circle (if needed).
    const maxAvatarCount =
      specialFormatting && specialFormatting["maxAvatarCount"]
        ? parseInt(specialFormatting["maxAvatarCount"])
        : 5;
    if (!maxAvatarCount || maxAvatarCount === 0) {
      return [];
    }

    // Note that a blank avatar filename starting with "_" doesn't get found on
    // our GitHub Pages site, for some reason. Hence the filename "aaa_blank.png".
    const shouldHideBlankAvatars =
      specialFormatting && specialFormatting["hideBlankAvatars"]
        ? specialFormatting["hideBlankAvatars"]
        : false;
    let blankAvatarPath = "";
    if (!shouldHideBlankAvatars) {
      blankAvatarPath = "./staff/aaa_blank.png";
    }

    let result = getAuthorAvatars(authors, blankAvatarPath);
    // Sort avatars so the ones with non-blank avatars are on top (because we favor pictures over blanks)
    result = result.sort((a, b) =>
      a.includes("aaa_blank") ? 1 : b.includes("aaa_blank") ? -1 : 0
    );
    let authorCount = result.length; // total number of authors in record

    if (result.length > maxAvatarCount)
      result = result.slice(0, maxAvatarCount - 1);

    // If we have overflow, append an element representing that number.
    if (authorCount - result.length > 0)
      result = [...result, "+" + (authorCount - result.length)];

    return result;
  }; // end avatarPaths

  //for importing au-impact images
  //   const getImageList = () => {
  //     const imgSRC = imageResolution(
  //         valueOfFirstField(rec, headers, cardView.title) + cardView.imageExt,
  //         "./data-au-research/images-impact",
  //         "./images/transparent.png" // invisible image to help with css layout
  //       );
  //   }

  // const authorFirstName = data[dataCardView["authors"]].map(({entry}) => [entry][0].split(' ')[0]);
  // console.log('authorFirstName', authorFirstName)
  // const authorLastName = data[dataCardView["authors"]].map(({entry}) => console.log('last',[entry][0]));

  //`=HYPERLINK\\(""?*.?""? *[;|,] *""?(.*?)""?\\)`

  // PRISM-17 - Account for taller mapped authors block
  const avatarItemStyle = {
    marginBottom:
      mappedAuthors.length > 1 ? `${mappedAuthors.length * 23}px` : "0.5rem",
  };

  const _avatarPaths = avatarPaths().reverse(); // fetch authors avatar images

  // PRISM-24 - Account for taller timeframe block when 2 or more are displayed
  // TODO - Move this to CSS if possible
  const timeframeStyle = {
    marginTop: timeframe.length > 1 ? "5px" : "0px",
  };

  return (
    <div className="DataCard">
      {(title.length > 0 ||
        subtitle.length > 0 ||
        _avatarPaths.length > 0 ||
        timeframe.length > 0 ||
        mappedAuthors.length > 0) && (
        <div className="headContainer">
          <div className="authorImage">
            <div className="avatars">
              {_avatarPaths.map(
                (path, index) =>
                  // When specialFormatting["hideBlankAvatars"] is true, we have not image path to use so just us an empty tag to skip it
                  path === undefined ? <></> : (
                    path.startsWith("+") || path.length < 5 ? (
                      <span key={`${path}-${index}`} style={avatarItemStyle}>
                        {path}
                      </span> // e.g. "+1"
                    ) : (
                      <img
                        key={`${path}-${index}`}
                        style={avatarItemStyle}
                        src={path}
                        alt={path}
                      />
                    ) // an actual path to an image
                  )
              )}
            </div>
          </div>
          <div className="headInnerContainer">
            {(title || subtitle) && getTitleAndSubtitleBlock()}
            <div className="headDetails">
              {timeframe && timeframe.length > 0 && (
                <div className="year timeframe" style={timeframeStyle}>
                  {timeframe}
                </div>
              )}
              {mappedAuthors && mappedAuthors.length > 0 && (
                <div className="authors">
                  <div className="authorsContainer">{mappedAuthors}</div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {info && info.length > 0 && <div className="description">{info}</div>}

      {nonCoreList}
    </div>
  );
}

export default DataCard;
