import React from "react";
import SideNav from "@hig/side-nav";
import "../App.css";

function SideNavBar(props) {
  return (
    <div className="sidebar">
      <div className="side-nav-container">
        <SideNav
          headerLabel="PRISM"
          headerLink="."
          superHeaderLabel="Powered by"
          superHeaderLink="."
          copyright="© 2024 Autodesk, Inc."
          links={[
            <SideNav.Link
              title="About Prism (wiki)"
              link="https://wiki.autodesk.com/display/PRISM"
              target="_blank"
            />,
            <SideNav.Link
              title="Discuss in Slack"
              link="slack://channel?team=T02NW42JD&id=CK5JMLRDX"
              target="_blank"
            />,
            // <SideNav.Link
            //   title="Feedback Survey"
            //   link="https://autodeskfeedback.az1.qualtrics.com/jfe/form/SV_7OkwjDqeUE8I9z7"
            //   target="_blank"
            // />,
            <SideNav.Link
              title="&nbsp;" // hack! need a real separator
            />,
            <SideNav.Link
              title="DEVELOPERS" // hack! need a real section title
            />,
            <SideNav.Link
              title="&nbsp;&nbsp;&nbsp;&nbsp;GitHub" // hack! to create an indent (in lieu of section title)
              link="https://git.autodesk.com/xd/prism"
              target="_blank"
            />,
            <SideNav.Link
              title="&nbsp;&nbsp;&nbsp;&nbsp;Jira" // hack! to create an indent (in lieu of section title)
              link="https://jira.autodesk.com/projects/PRISM/"
              target="_blank"
            />,
            <SideNav.Link
              title="&nbsp;&nbsp;&nbsp;&nbsp;HIG" // hack! to create an indent (in lieu of section title)
              link="https://hig.autodesk.com"
              target="_blank"
            />,
            <SideNav.Link
              title="&nbsp;&nbsp;&nbsp;&nbsp;Slack" // hack! to create an indent (in lieu of section title)
              link="slack://channel?team=T02NW42JD&id=CEZK708TX"
              target="_blank"
            />,
            <SideNav.Link
              title="&nbsp;&nbsp;&nbsp;&nbsp;Analytics" // hack! to create an indent (in lieu of section title)
              link="https://analytics.google.com/analytics/web/#/realtime/rt-event/a138572219w199248583p193744334/metric.type=5/"
              target="_blank"
            />,
            <SideNav.Link
              title="&nbsp;" // hack! need a real separator
            />,
          ]} //links
        />
      </div>
    </div>
  );
}

export default SideNavBar;
