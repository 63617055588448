import React from "react";
import imageResolution from "../imageMediator";

function Detail({
  showingDetailArea,
  image,
  selectionX,
  selectionY,
  imageDir,
  defaultImage,
  newDataset
}) {

  return newDataset ? null : showingDetailArea ? (
    <div className="detail">
      <img
        src={
          imageResolution(image, imageDir, defaultImage)
        }
        alt="map"
      />
      <div
        className={image ? "detailHighlight" : ""}
        style={{
          left: `${selectionX * 100 - 5}%`, // subtract half of .detailHighlight width
          top: `${selectionY * 100 - 5}%`, // subtract half of .detailHighlight height
        }}
      />
    </div>
  ) : null;
}

export default Detail;
