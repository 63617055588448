import React, { useCallback } from "react";
import Detail from "./Detail";
import MainList from "./MainList";
import {
  getHTMLForListItems
} from "../utilities/datasetManager";
import {
  filterRecords
} from "../utilities/searchManager";
import EmptyState from "../images/empty-symbol-128.png";
import { 
  useDatasetTableStore
} from '../store/datasetTable';

// Build list items
function DatasetView({
  dataset,
  searchString,
  loading,
  selection,
  selectRowLegacy
}) {

  // datasetEntries store methods
  // cache or memoize the value with react's useCallback hook and reuse the result as long as the csvId doesn't change
  const currentTable = useDatasetTableStore(
    useCallback(state => {
        const found = state.tables.find(table => table.key === dataset.id);
        return found ? found.data : null;
      }, 
      [
        dataset.id
      ]
    )
  );

  /* This returns a chunk of HTML representing 
  the list items that match our filter. */
  const getFilteredHTMLForListItems = () => {
    if (!currentTable) return []; //to account for time it takes to load default dataset
    const matchingRecords = filterRecords(dataset, currentTable, searchString); //dataset = current state variable
    const datasetHeaders = currentTable.headers;

    /* Now that we have the subset of matching records,
    generate the corresponding HTML. */

    return getHTMLForListItems(
      matchingRecords,
      datasetHeaders,
      dataset,
      selectRowLegacy,
      searchString
    );
  }; // end getFilteredHTMLForListItems

  // caching result of getFilteredHTMLForListItems to variable to save it from running again
  const filteredHTMLList = getFilteredHTMLForListItems();

  return filteredHTMLList.length > 0 ? (
    <div key={dataset.id} className="mLAndDetailParent">
      <MainList
        datasetList={filteredHTMLList}
        dataset={dataset}
        loading={loading}
      />
      <Detail
        showingDetailArea={dataset.hasItemDetail}
        image={selection.image}
        selectionX={selection.selectionX}
        selectionY={selection.selectionY}
        imageDir={dataset.imageDir}
        defaultImage={dataset.defaultImage}
      />
    </div>
  ) : (
    <div className="noResult"> 
    <img src={EmptyState} alt="HIG empty state icon"></img>
    {/* <span>Uh oh, no records found!</span> */}
    </div>
  );
}

export default DatasetView;
