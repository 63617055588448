import React, { useEffect, useState } from "react";
import TopNav from "@hig/top-nav";
import "../App.css";
import IconButton from "@hig/icon-button";
import {
  HamburgerDefaultUI,
  HamburgerCloseUI,
  Search24,
  Info16,
  CaretDownMUI,
  CaretUpMUI,
  CloseSUI,
  Unavailable16,
} from "@hig/icons";
import Flyout, { anchorPoints } from "@hig/flyout";
//import Menu, { Option } from "@hig/menu";
import Input from "@hig/input"; //allows input of freeform text up to 100 characters
import AutodeskIcon from "../images/adskIcon-rosetta-512.png";
import { superParser } from "../utilities/datasetManager";

function NavBar({
  sideNavIsOpen,
  toggleSideNavOpen,
  toggleSideNavClose,
  datasetCategories,
  onLoadInputFile,
  testDatasetCategories,
  categoryLabel,
  datasetLabel,
  info,
  totalRecordCount,
  searchString,
  setSearchString,
}) {
  const [cachedSearchString, setCachedSearchString] = useState(searchString);
  const [cachedCategoryLabel, setCachedCategoryLabel] = useState(categoryLabel);
  const [isFlyoutOpen, setIsFlyOutOpen] = useState(false);

  useEffect(() => {
    let didCancel = false;
    setTimeout(() => {
      if (!didCancel) setSearchString(cachedSearchString);
    }, 500);
    return () => {
      didCancel = true;
    }; // cleanup function for useEffect
  }, [cachedSearchString]);

  useEffect(() => {
    if (categoryLabel !== cachedCategoryLabel) {
      setSearchString("");
      setCachedSearchString("");
    }
    setCachedCategoryLabel(categoryLabel);
  }, [categoryLabel]);

  const tabInfoLabel = categoryLabel;
  const searchPlaceholder = "Search all " + categoryLabel;

  const closeFlyOut = () => {
    setIsFlyOutOpen(false);
  };

  const onUserFileChanged = (event) => {
    switch (event.target.files.length) {
      case 1:
        onLoadInputFile(event.target.files[0]);
        break;

      case 2:
        const csvFileRegex = /\/[csv|tsv]+$/;
        const csvJsonRegex = /\/json$/;
        let csvFile, jsonFile;
        if (
          event.target.files[0] !== undefined &&
          event.target.files[0].type.match(csvFileRegex) !== null
        ) {
          csvFile = event.target.files[0];
          if (
            event.target.files[1] !== undefined &&
            event.target.files[1].type.match(csvJsonRegex) !== null
          ) {
            jsonFile = event.target.files[1];
          }
        } else if (
          event.target.files[1] !== undefined &&
          event.target.files[1].type.match(csvFileRegex) !== null
        ) {
          csvFile = event.target.files[1];
          if (
            event.target.files[0] !== undefined &&
            event.target.files[0].type.match(csvJsonRegex) !== null
          ) {
            jsonFile = event.target.files[0];
          }
        } else {
          return;
        }
        onLoadInputFile(csvFile, jsonFile);
        break;

      default:
        alert("Please pick one data file, or one data file and one metadata file.");
        break;
  
    }
  };

  return (
    <div className="top-nav-component">
      <TopNav
        className="top-nav-container"
        logo={
          <>
            <div className="top-nav">
              <img src={AutodeskIcon} alt="Autodesk Icon" />
              <div className="site-title">PRISM</div>
              <div className="separator">&nbsp;</div>
              {
                /* Our menu of "approved" datasets/collections. */
                /* Hack; we should use a menu component here but I don't know how to
                 * code that. So for now, we use static text next to a downward caret,
                 * and we have a special CSS class for it to skooch it closer to the
                 * static text.
                 */
                <Flyout
                  className="caret-icon"
                  maxHeight={500}
                  anchorPoint={anchorPoints.TOP_RIGHT}
                  open={isFlyoutOpen}
                  content={() => (
                    <div>
                      <div className="flyout-label">Data&nbsp;collections</div>
                      <div onClick={closeFlyOut}>{datasetCategories}</div>
                    </div>
                  )}
                >
                  <div
                    className="current-collection"
                    onClick={() => setIsFlyOutOpen(!isFlyoutOpen)}
                  >
                    {categoryLabel}
                    {isFlyoutOpen ? <CaretUpMUI /> : <CaretDownMUI />}
                  </div>
                </Flyout>
              }
              {info && (info = superParser(info)) && (
                /* Flyout with info about the selected tab. */
                /* Note: we don't open our info flyout on hover, because then it would close
              on de-hover, and we would not be able to click any links inside of it.
              Also note: using <p> here instead of <div> gives nicer padding above
              and below the info content. */
                <Flyout
                  className="topnav-icon"
                  anchorPoint={anchorPoints.TOP_LEFT}
                  content={({ hideFlyout }) => (
                    <div onClick={hideFlyout}>
                      <div className="flyout-label">{tabInfoLabel}</div>
                      <div className="flyout-dataset-label">{datasetLabel}</div>
                      <div>{info}</div>
                    </div>
                  )}
                >
                  <IconButton
                    className="info-flyout"
                    title={
                      "Dataset info for " + tabInfoLabel + " | " + datasetLabel
                    }
                    icon={<Info16 />}
                  />
                </Flyout>
              )}

              <div className="search search-desktop">
                <label>
                  <Search24 />
                </label>
                <Input
                  type="text"
                  id="search-desktop"
                  placeholder={searchPlaceholder}
                  value={cachedSearchString}
                  onChange={(e) => setCachedSearchString(e.target.value)}
                  autoFocus="autoFocus"
                  variant={"line"}
                />
                <button
                  icon={<CloseSUI />} // hack; this doesn't show up, so for now we use the svg below
                  className="clear-search"
                  aria-label="clear search"
                  onClick={() => setCachedSearchString("")}
                >
                  <svg
                    id="assets"
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                  >
                    <g id="Mask">
                      <polygon
                        id="path-1"
                        points="13.4 3.4 12.6 2.6 8 7.3 3.4 2.6 2.6 3.4 7.3 8 2.6 12.6 3.4 13.4 8 8.7 12.6 13.4 13.4 12.6 8.7 8 13.4 3.4"
                      />
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            {totalRecordCount >= 0 && (
              <div className="record-count">{totalRecordCount}&nbsp;items</div>
            )}
          </>
        }
        leftActions={
          <div className="topnav-icon">
            {sideNavIsOpen ? (
              <IconButton
                title={"Close Button"}
                icon={<HamburgerCloseUI />}
                onClick={toggleSideNavClose}
              />
            ) : (
              <IconButton
                title={"Menu Button"}
                icon={<HamburgerDefaultUI />}
                onClick={toggleSideNavOpen}
              />
            )}
          </div>
        }
        rightActions={
          <div className="topnav-right">
            {
              /* Our menu of test/experimental datasets/collections. */
              <Flyout
                className="topnav-icon"
                maxHeight={500}
                anchorPoint={anchorPoints.TOP_RIGHT}
                content={({ hideFlyout }) => (
                  <div>
                    <div className="flyout-label">Experimental - Beware!</div>
                    <div onClick={hideFlyout}>{testDatasetCategories}</div>
                    <div className="flyout-label">Import (Local)</div>
                    <input
                      className="csv-input"
                      type="file"
                      multiple
                      name="files[]"
                      //accept="text/csv"
                      placeholder={"Load CSV File"}
                      onChange={onUserFileChanged}
                    />
                  </div>
                )}
              >
                <IconButton title="Dev Tools" icon={<Unavailable16 />} />
              </Flyout>
            }
          </div>
        }
      />
    </div>
  );
}

export default NavBar;
