//Helper function needs to stay inside src folder
//so that Detail component within component folder can
//properly render images (source directory issue; need to adjust webpack.config)

function imageResolution(imageFileName, imageFolderPath, defaultImagePath) {
  try {
    return require(`${imageFolderPath}${imageFileName}`);
  } catch {
    if ((defaultImagePath === undefined) || (defaultImagePath === ""))
      return undefined;
    return require(`${defaultImagePath}`);
  }
} // end imageResolution

export default imageResolution;
