import React from "react";
import { Datasets } from "../components/Datasets";
import Button from "@hig/button";
import imageResolution from "../imageMediator";
import DataCard from "../components/DataCard";
import { Checkmark16 } from "@hig/icons";
import staffListJson from "../staff/list.json";
import { SEARCH_OPTION_WHOLE_WORD, getMatchRegex, containsQuotedWords } from "./searchManager";

//////////////////////////////////////////////////////////////////////////

/**
 * @param {string || object} fieldValue
 * @param {string} urlValue
 * @returns {string}
 */
function applyLinkToFields(fieldValue, urlValue) {
  //console.log('applyLinkToFields', fieldValue, urlValue);

  if (fieldValue.trim() === "" || urlValue === null || urlValue.trim() === "")
    return fieldValue;

  return urlValue.startsWith("<") && urlValue.endsWith(">")
    ? urlValue
    : `<${urlValue}|${fieldValue.replace(/\n|\r/gi, "").trim()}>`;
} // end applyLinkToFields

//////////////////////////////////////////////////////////////////////////

// We're not using this yet but keep it around as a reminder

// function stringHasContent(s) {
//   return s !== undefined && s !== null && s !== "";
// }

//////////////////////////////////////////////////////////////////////////

// Returns the HTML for a single button, corresponding to the
// indicated dataset, for use in our dataset-switching flyout.

function htmlForDatasetButton(label, isSelected, switchTo) {
  // Does an arrow function in onClick cause unnecessary renders? Do we care? Reference:
  // https://github.com/yannickcr/eslint-plugin-react/blob/master/docs/rules/jsx-no-bind.md
  const handleBtnClick = () => {
    switchTo(label);
  };

  return isSelected ? (
    <div key={label}>
      <Button
        className="menu-item"
        key={label}
        type="flat"
        icon={<Checkmark16 />}
        title={label}
        onClick={handleBtnClick}
      />
    </div>
  ) : (
    <div key={label}>
      <Button
        className="menu-item"
        key={label}
        type="flat"
        icon={<></>} // without this, spacing doesn't match selected item
        title={label}
        onClick={handleBtnClick}
      />
    </div>
  );
} // end htmlForDatasetButton

//not a component so don't need to pass in 'props'; not rendering

// This returns an array of HTML chunks, each of which represents a dataset
// collection button. switchTo is what gets called when the button is clicked.
// If test is false, we return our "released" collections, otherwise we return
// our "experimental" collections. (This is how we implement separate menus for
// the different kinds of collections.)

function getHTMLForDatasetCategories(switchTo, selectedLabel, test = false) {
  //hide any keys whose corresp array of dataset (each obj inside array has hidden true)
  let result = Object.entries(Datasets)
    .filter(
      // omit any collections where all datasets are hidden
      (
        [collectionId, collectionArray] //destructuring collection that has been turned into an array of [key, value]
      ) => !collectionArray.datasets.every((entry) => entry.hidden)
    )
    .filter(
      // if test=false, omit any collections whose name starts with "#", and vice versa
      (
        [collectionId, collectionArray] //destructuring collection that has been turned into an array of [key, value]
      ) => (test ? collectionId[0] === "#" : collectionId[0] !== "#")
    )
    .map((subArray) =>
      htmlForDatasetButton(subArray[0], subArray[0] === selectedLabel, switchTo)
    ); //[collectionId, collectionArray] - we want just collectionId, which is at the 0th index

  return result;
} // end getHTMLForDatasetCategories

//////////////////////////////////////////////////////////////////////////

//to create tabs for each of menuSection's value from Dataset.js after we get collection ID

function getDatasetsforCategory(category) {
  return Datasets[category].datasets;
} // end getDatasetsforCategory

//////////////////////////////////////////////////////////////////////////

// https://jira.autodesk.com/browse/PRISM-15 - Implement linkFields for datasets

function applyLinkFields(dataset, data) {
  const appliedLinkFields = [];
  const linkFields = dataset.linkFields;
  // if the dataset contains no linkFields option, bail out
  if (linkFields === null || linkFields === undefined) {
    return data;
  }
  data.headers.map((fieldName) => {
    // Skip multiple headers if we have processed one already
    if (appliedLinkFields.includes(fieldName)) {
      return fieldName;
    }

    // Check if the current header is supposed to be linked
    const matchingLinkField = linkFields.find(
      (el) => el.text.toLowerCase() === fieldName.toLowerCase()
    );
    if (matchingLinkField === undefined) {
      // Keep headers untouched
      return fieldName;
    }
    // Then look for the field containing the URL
    const urlDataIndex = data.headers.findIndex(
      (_header) => _header.toLowerCase() === matchingLinkField.url.toLowerCase()
    );
    if (urlDataIndex === -1) {
      // Keep headers untouched
      return fieldName;
    }

    // Then look for the field we are replacing with the URL
    const textDataIndex = data.headers.findIndex(
      (_header) =>
        _header.toLowerCase() === matchingLinkField.text.toLowerCase()
    );
    if (textDataIndex === -1) {
      // Keep headers untouched
      return fieldName;
    }

    // and make sure we have the field containing the Url
    data.records.map((row, rowIndex) => {
      const urlForLinkField = row[urlDataIndex];
      if (urlForLinkField === undefined) {
        // Keep data untouched
        return row;
      }

      const entryToBeLinked = row[textDataIndex];
      if (entryToBeLinked === undefined) {
        // Keep data untouched
        return row;
      }

      // Remove the field that used to store the URL
      data.headers.splice(urlDataIndex, 1);
      row.splice(urlDataIndex, 1);

      // Save the matched field so we filter it out later on
      appliedLinkFields.push(matchingLinkField.text);

      // Finally apply the link
      row[textDataIndex] = applyLinkToFields(entryToBeLinked, urlForLinkField);

      return row;
    });

    return null;
  });

  //console.log('data', data);

  return data;
} // end applyLinkFields

//////////////////////////////////////////////////////////////////////////

function getHTMLForListItems(
  rowsToShow,
  headers,
  dataset,
  onListItemClick,
  searchString,
  searchType
) {
  const showingItemSeparator = !dataset.hideItemSeparator;

  const selection = null;
  const htmlForListItems = [];
  if (rowsToShow.length) {
    for (let recIndex = 0; recIndex < rowsToShow.length; recIndex++) {
      htmlForListItems.push(
        htmlForListItem(
          rowsToShow[recIndex],
          headers,
          selection, //set to null above
          dataset,
          onListItemClick,
          searchString,
          searchType
        )
      );

      if (
        !dataset.cardView &&
        showingItemSeparator &&
        recIndex < rowsToShow.length - 1
      ) {
        // Using the array index and dataset.id for key
        // TODO - Make this a bit safier
        htmlForListItems.push(<hr key={`hr-${recIndex}`} />);
      }
    }
  }
  return htmlForListItems;
} // end getHTMLForListItems

//////////////////////////////////////////////////////////////////////////

/**
 * Returns true if any element of the input array matches
 * the input string regardless of case; otherwise returns false.
 * @returns {boolean}
 */

function arrayIncludesCaseInsensitive(arr, str) {
  const lower = str.toLowerCase();
  const iFound = arr.findIndex((e) => e.toLowerCase() === lower);
  return iFound >= 0;
} // end arrayIncludesCaseInsensitive

//////////////////////////////////////////////////////////////////////////
/**
 * Given a record and a set of headers, and a field name to look for, this
 * returns the value of the first field whose header matches the field name.
 * @returns {string}
 */
function valueOfFirstField(rec, headers, fieldName) {
  if (fieldName === undefined) return undefined;
  const fieldLookup = fieldName.toLowerCase();
  const iField = headers.findIndex(
    (header) => header.toLowerCase() === fieldLookup
  );
  return iField >= 0 ? rec[iField] : "";
} // end valueOfFirstField

//////////////////////////////////////////////////////////////////////////
/**
 * Escapes special characters in the given text so that it can be used in a regex.
 * @param {string} needle
 * @returns {string}
 */
const escapeRegExp = (needle) => {
  return needle.replace(/[.*+?^${}()|[\]\\]/g, "\\$&"); // $& means the whole matched string
};

//////////////////////////////////////////////////////////////////////////
/**
 * Removes string from header string ending.
 * @param {Object} dataset
 * @param {Object} data
 * @returns {Object}
 */
const applyRemoveHeaderEndings = (dataset, data) => {
  const removeHeaderEndings = dataset.removeHeaderEndings;
  if (removeHeaderEndings === undefined || removeHeaderEndings.length === 0) {
    return data;
  }

  data.headers.forEach((header, index) => {
    data.headers[index] = stringWithoutEndings(removeHeaderEndings, header);
  });

  return data;
};

//////////////////////////////////////////////////////////////////////////

/**
 * Returns the input string with any of the specified endings ("needles") removed.
 * Removal occurs in the order that the endings occur in the array of endings.
 * @param {Array} needles
 * @param {String} input
 * @returns {String}
 */

function stringWithoutEndings(needles, input) {
  for (const needle of needles) {
    input = input
      .replace(new RegExp(`${escapeRegExp(needle)}$`, "gi"), "")
      .trim();
  }
  return input;
} // end stringWithoutEndings

//////////////////////////////////////////////////////////////////////////

let rowUniqueNumber = 0;
function parseGenericList(
  rec,
  headers,
  dataset,
  searchString,
  searchType,
  specialFormatting
) {
  const showingFieldNames = !dataset.hideFieldNames;
  const fieldsToItalicize = dataset.fieldsToItalicize;
  const fieldsToBold = dataset.fieldsToBold;
  const fieldsToExclude = dataset.fieldsToExclude;
  const showingEmptyFields = !dataset.hideEmptyFields;
  // Here if we are using standard formatting. This is the case
  // for user-uploaded data, and for some built-in datasets.
  if (specialFormatting) {
    // PRISM-8 - Support empty special formatting with default mapping with commonly used column names
    // https://wiki.autodesk.com/display/~jensenl/Prism+-+Special+Formatting+and+Field+Blocks
    const _specialFormatting = {
      titleFields: specialFormatting.titleFields || ["Title", "Name"],
      subtitleFields: specialFormatting.subtitleFields || "Subtitle",
      dateFields: specialFormatting.dateFields || ["Timeframe", "Date", "Year"],
      authorFields: specialFormatting.authorFields || ["Author", "Authors"],
      descriptionFields: specialFormatting.descriptionFields || [
        "Description",
        "Abstract",
        "Summary",
      ],
    };

    //data creates an obj; converts current array of array of strings in rec and headers into 1 obj
    const data = Object.values(_specialFormatting).reduce(
      //if value of a field is an array, iterate thru the array to access each header --aka "subHeader"

      (acc, header) =>
        Array.isArray(header)
          ? {
              ...acc,
              [header]: header.map((subHeader) => ({
                entry: valueOfFirstField(rec, headers, subHeader),
                subHeader,
              })), //returns [{entry: [valueOfFirstField], subHeader: subHeader}, {}, ...]
            }
          : {
              ...acc,
              [header]: [
                {
                  entry: valueOfFirstField(rec, headers, header),
                  subHeader: header,
                },
              ],
            },
      {}
    );
    const filterArray = headers.map(
      (el) =>
        !Object.values(_specialFormatting)
          .flatMap((valueInsideFormatting) => valueInsideFormatting)
          // Don't repeat specialFormating fields rendered under DataCard
          .some(
            (_lookupHeader) => _lookupHeader.toLowerCase() === el.toLowerCase()
          )
    );

    const infoFilterArray = headers.map((header) =>
      typeof _specialFormatting.descriptionFields === "string"
        ? _specialFormatting.descriptionFields === header
        : _specialFormatting.descriptionFields.includes(header)
    ); //specialFormatting.descriptionFields can be a string or array
    const info = parseGenericList(
      rec.filter((el, i) => infoFilterArray[i]),
      headers.filter((el, i) => infoFilterArray[i]),
      dataset,
      searchString,
      searchType,
      false //not using specialFormatting explicitly
    );

    //create list of non-core fields' header to field
    //array of arrays where subarray of 0 index is key, subarray of 1 index is value
    const nonCoreList = parseGenericList(
      rec.filter((el, i) => filterArray[i]),
      headers.filter((el, i) => filterArray[i]),
      dataset,
      searchString,
      searchType,
      false //not using specialFormatting explicitly
    );

    // Merge non-header related special formatting options before passing it on to DataCard - PRISM-35
    _specialFormatting.hideBlankAvatars =
      specialFormatting.hideBlankAvatars || false;

    return (
      <DataCard
        specialFormatting={_specialFormatting}
        data={data}
        info={info}
        searchString={searchString}
        searchType={searchType}
        nonCoreList={nonCoreList}
      />
    );
  }
  //map headers to true or false array, filter both headers and rec by that true and false array
  return headers.map((fldName, headerIndex) => {
    if (fieldsToExclude && fieldsToExclude.includes(fldName)) return null; // avoids compile warning

    let fldValueRaw = rec[headerIndex]; //valueOfFirstField(rec, headers, fldName);

    let fldValue = [];

    // Note: For multi-line fields, we use fldValueRaw as the value to split,
    // because fldValue doesn't work there (it renders as "[object Object]").

    if (
      fldValueRaw !== undefined &&
      (showingEmptyFields || fldValueRaw !== "")
    ) {
      // Replace each line break in the field value with a div wrapper.
      fldValue = fldValueRaw.split(/\n|\r\n|\r/g).map((t, mapIndex) => {
        return (
          <div
            key={`map-${dataset.id}-${fldName}-${headerIndex}-${rowUniqueNumber}-${mapIndex}-${t}`}
          >
            {superParser(t, searchString, searchType)}
          </div>
        ); //need to revisit keys
      });
    } else return null;

    // Decide if this field will be in bold. If the dataset
    // has specified a list of bold fields, we'll use that.
    // Otherwise, we bold fields with certain special names.

    let usingBoldForFieldValue = fieldsToBold
      ? arrayIncludesCaseInsensitive(fieldsToBold, fldName)
      : fldName.toLowerCase() === "name" || fldName.toLowerCase() === "title";

    // Decide if this field will be in italics. If the dataset
    // has specified a list of italic fields, we'll use that.
    // Otherwise, there are no default fields to italicize.

    let usingItalicForFieldValue = fieldsToItalicize
      ? arrayIncludesCaseInsensitive(fieldsToItalicize, fldName)
      : false;

    rowUniqueNumber++;

    return (
      <div key={`${dataset.id}-${fldName}-${headerIndex}`} className="field">
        {showingFieldNames && <div className="field-label">{fldName}</div>}
        <div key={`${dataset.id}-${fldName}-${headerIndex}-${fldValue}`}>
          {usingItalicForFieldValue && usingBoldForFieldValue && (
            <b>
              <i>
                <div className="field-value">{fldValue}</div>
              </i>
            </b>
          )}
          {!usingItalicForFieldValue && usingBoldForFieldValue && (
            <b>
              <div className="field-value">{fldValue}</div>
            </b>
          )}
          {usingItalicForFieldValue && !usingBoldForFieldValue && (
            <i>
              <div className="field-value">{fldValue}</div>
            </i>
          )}
          {!usingItalicForFieldValue && !usingBoldForFieldValue && (
            <div className="field-value">{fldValue}</div>
          )}
        </div>
      </div>
    );
  });
} //end of parseGenericList

//////////////////////////////////////////////////////////////////////////

function parseCustomList(rec, headers) {
  const listItemElements = [];
  //if usingCustomStyling is true
  //       // Here if we are using custom styling for room finder data.
  //       // Here are the fields in that file, with example values:
  //       //
  //       // Name: Leinnaeus
  //       // Number: 24090
  //       // Building: Steuart
  //       // Floor: 4
  //       // Image: sf-steuart-2-3-4.png
  //       // X: 0.224
  //       // Y: 0.271
  //       // Seats: 16
  //       // Notes: See also *_Leinneaus_* (restricted) on *Landmark 4*
  //       // Description: The *_Linnaeus_* game room (24090) is on *Steuart 4*, northwest corner.
  //       // Namesake: Carl Linnaeus
  //       // URL: https://wikipedia.org/wiki/Carl_Linnaeus

  const Name = valueOfFirstField(rec, headers, "Name");
  const Number = valueOfFirstField(rec, headers, "Number");
  const Building = valueOfFirstField(rec, headers, "Building");
  const Floor = valueOfFirstField(rec, headers, "Floor");
  const X = valueOfFirstField(rec, headers, "X");
  const Y = valueOfFirstField(rec, headers, "Y");
  const Seats = valueOfFirstField(rec, headers, "Seats");
  const Notes = valueOfFirstField(rec, headers, "Notes");
  const Description = valueOfFirstField(rec, headers, "Description");
  const Namesake = valueOfFirstField(rec, headers, "Namesake");
  const URL = valueOfFirstField(rec, headers, "URL");

  if (Name && Number)
    listItemElements.push(
      <span key={Number} className="room">
        {Number} {Name.split("|")[0]}
      </span>
    );
  if (Building && Floor) {
    if (Description) {
      listItemElements.push(
        <span key={Building} className="location">
          {Building} {Floor}, {Description.split(", ")[1].split(".")[0]}
        </span>
      );
    } else {
      // no Description -- avoid superfluous comma
      listItemElements.push(
        <span className="location">
          {Building} Floor {Floor}
        </span>
      );
    }
  }
  if (X && Y)
    listItemElements.push(
      <div key={X} className="circle" left="{X}" top="{Y}" />
    );
  if (rec.Seats)
    listItemElements.push(
      <span key={Namesake + Seats} className="seats">
        {Seats} seats
      </span>
    );
  if (Notes)
    listItemElements.push(
      <span key={Notes} className="notes">
        {Notes.replace(/_/g, "").replace(/\*/g, "")}
      </span>
    );
  if (URL && Namesake) {
    listItemElements.push(
      <span key={Y} className="namesake">
        <a href={URL} target="prism">
          {Namesake}
        </a>
      </span>
    );
  }
  return listItemElements;
} //end of parseCustomList

//////////////////////////////////////////////////////////////////////////

function htmlForListItem(
  rec,
  headers,
  selectedItemId,
  dataset,
  onListItemClick,
  searchString,
  searchType
) {
  const usingCustomStyling = dataset.hasCustomStyling;
  const cardView = dataset.cardView;
  const listItemClickHandler = onListItemClick;

  let listItemElements = [];

  if (usingCustomStyling) {
    listItemElements = parseCustomList(rec, headers);
  } else if (cardView) {
    const imgSRC = imageResolution(
      valueOfFirstField(rec, headers, cardView.title) + cardView.imageExt,
      cardView.imageDir,
      "./images/transparent.png" // invisible image to help with css layout
    );
    //create title, body, link
    listItemElements.push(
      /* <></> = React fragments, or empty tags, that allow a component
      to return a group of children without adding extra nodes to DOM.
      */
      <>
        <div className="header">
          <div className="title">
            {superParser(
              valueOfFirstField(rec, headers, cardView.title),
              searchString,
              searchType
            )}
          </div>
          <img className="image" src={imgSRC} alt="prod img" />
        </div>
        <div className="body">
          <div className="content">
            {superParser(
              valueOfFirstField(rec, headers, cardView.body),
              searchString,
              searchType
            )}
          </div>
        </div>
        <div className="footer">
          <div className="link">
            {superParser(
              valueOfFirstField(rec, headers, cardView.link),
              searchString,
              searchType
            )}
          </div>
        </div>
      </>
    );
  } else {
    listItemElements = parseGenericList(
      rec,
      headers,
      dataset,
      searchString,
      searchType,
      "specialFormatting" in dataset ? dataset.specialFormatting : null
    );
  }

  let itemId = rec.Number + " " + rec.Name;
  let itemIsSelected = String(itemId === selectedItemId);
  if (!usingCustomStyling) itemIsSelected = null; // we want selection only if we have a detail area

  return (
    <li
      className="card"
      id={itemId}
      key={`${dataset.id}-${rec}`}
      /* PRISM-45:Text selection is practically unusable > Only trigger the selectRowLegacy method when viewing Conference Rooms (details) */
      onClick={() => (dataset.hasItemDetail ? listItemClickHandler(rec) : null)}
      data-selected={itemIsSelected}
    >
      {listItemElements}
    </li>
  );
} // end htmlForListItem

//////////////////////////////////////////////////////////////////////////

//to get initial load to render first element in Datasets.js

function getFirstDataset(collectionId) {
  //if we don't get params, default to first dataset in a collection
  if (!collectionId || !Datasets[collectionId])
    return Object.values(Datasets)[0].datasets[0];
  //if we do get collection id, get first dataset out of said collection
  else return Datasets[collectionId].datasets[0];
} // end getFirstDataset

//////////////////////////////////////////////////////////////////////////

// Returns the metadata of the first dataset that corresponds to the
// given ID. If the ID does not exist, or is null, then null is returned.
// TBD: Needs a unit test.

function datasetMetadataFromId(id) {
  return Object.values(Datasets)
    .flatMap((e) => e.datasets)
    .find((e) => e.id === id);
} // end datasetMetadataFromId

//////////////////////////////////////////////////////////////////////////

function getFirstDatasetCategory() {
  //grab first dataset that's not hidden in first category that exists in collection
  //categories to hide: Test & Debug, Decision Making
  return Object.keys(Datasets)[0];
} // end getFirstDatasetCategory

//////////////////////////////////////////////////////////////////////////

function getFirstCollectionWithDatasetId(datasetId) {
  return Object.entries(Datasets).find(([key, value]) =>
    value.datasets.some((dataset) => dataset.id === datasetId)
  );
} // end getFirstCollectionWithDatasetId

//////////////////////////////////////////////////////////////////////////

function findDatasetIndex(collectionId, datasetId) {
  if (Datasets[collectionId])
    return Datasets[collectionId].datasets.findIndex(
      (dataset) => dataset.id === datasetId
    );
  else return 0;
} // end findDatasetIndex

//////////////////////////////////////////////////////////////////////////

function findDatasetCategoryIndex(collectionId) {
  for (let collection of Object.entries(Datasets)) {
    if (
      collection[0] !== undefined &&
      collection[1] !== undefined &&
      collection[1].id === collectionId
    ) {
      return collection[0];
    }
  }
  return -1;
} // end findDatasetCategoryIndex

//////////////////////////////////////////////////////////////////////////

function isValidCollection(collectionId) {
  return Object.values(Datasets).some(
    (collection) => collection.id === collectionId
  );
} // end isValidCollection

//////////////////////////////////////////////////////////////////////////

function isValidDataset(collectionId, datasetId) {
  if (!collectionId || !datasetId || !Datasets[collectionId]) return false;
  const foundDataset = Datasets[collectionId].datasets.find(
    (dataset) => dataset.id === datasetId
  );
  // Make sure the dataset has a valid dataFile
  return (
    foundDataset !== undefined &&
    foundDataset.dataFile !== null &&
    foundDataset.dataFile !== undefined
  );
} // end isValidDataset

//////////////////////////////////////////////////////////////////////////

// Returns an array of strings and React Elements for highlights.

function getHighlighElements(str, regex, createElement) {
  if (regex.test(str) === false) {
    return [str];
  }

  const split =
    //creates nested array within parent array
    str
      .split(regex)
      // gets ride of undefined bits of the regex parse
      .filter((element) => element !== undefined);
  //console.log(`getHighlighElements ${str}, ${regex} => `, split);

  // changes regex if needed so we can find words that match search
  //const replacementRegex = /(\\b\(\((?!\!\.\*\)\\b).*)\\b\(/g; <-- gives build warning: unnecessary '\!'
  const replacementRegex = /(\\b\(\((?!\.\*\)\\b).*)\\b\(/g;
  const regexString = regex.toString();
  const wordRegex = regexString.includes("\\s?)\\b((?!.*")
    ? new RegExp(
        // Replace the matches separator with a regex OR operator so we can highlight the words
        regexString
          .replaceAll("\\", "\\\\") // escape backslashes
          .replaceAll(replacementRegex, "\\b|\\b(") // add word boundaries to look for each word speartely
          .replace("/", "")
          .replace("/gi", "") // clean up regez delimiters
          .replaceAll("\\\\", "\\"), // unescape backslashes
        "gmi"
      )
    : regex;
  //console.log('wordRegex', wordRegex);
  const arr = [];
  for (let part of split) {
    part = part.replaceAll('"', ""); // cleaning quotes after match
    if (wordRegex.test(part)) {
      // Check if the part contains a word that matches the search (recursive splitting by words)
      const innerParts = part
        .split(wordRegex)
        .filter((element) => element !== undefined && element !== ""); // skip undefined and empty strings
      //console.log(`innerParts for ${wordRegex} on ${part}`, innerParts);
      if (innerParts.length > 1) {
        //console.log('part contains a word that matches the search => ', innerParts);
        for (const innerPart of innerParts) {
          //console.log(`Looping thru innerParts => [${innerPart}] with [${wordRegex}]`, wordRegex.test(innerPart), innerPart.match(wordRegex));
          if (innerPart.match(wordRegex) !== null) {
            // Create highlight React component for matching word
            arr.push(createElement([innerPart]));
            //console.log(`getHighlighElements() matched for ${innerPart} regex!!!`, [...arr]);
          } else {
            arr.push(innerPart);
            //console.log(`getHighlighElements() NO MATCH (innerPart [${wordRegex}]) for [${innerPart}], use plainText!!!`, [...arr]);
          }
        }
      } else {
        // Create highlight React component for matching word
        arr.push(createElement([part]));
        //console.log(`getHighlighElements() matched for ${part} regex!!!`, [...arr]);
      }
    } else {
      arr.push(part);
      //console.log(`getHighlighElements() NO MATCH for [${part}], use plainText!!!`, [...arr]);
    }
  }
  //console.log('getHighlighElements() >>', arr);
  return arr;
} // end getHighlighElements

//////////////////////////////////////////////////////////////////////////

function parseLinks(str, regex, createElement, n) {
  const split =
    //creates nested array within parent array
    str
      .split(regex)
      // gets ride of undefined bits of the regex parse
      .filter((element) => element !== undefined);
  const arr = [];
  for (let i = 0; i < split.length - 1; i += n) {
    //each piece of data grouped in 3
    const plainText = split[i].replaceAll('"', ""); //need this item pushed into arr to keep indices in place
    const elementProperties = [];
    for (let j = i + 1; j <= split.length - 1; j++) {
      elementProperties.push(
        split[j].replaceAll('"', "") // cleaning quotes after match
      );
    }

    arr.push(plainText);
    arr.push(createElement(elementProperties));
  }
  arr.push(
    split[split.length - 1].replaceAll('"', "") // cleaning quotes after match
  );
  //console.log('parseLinks() >>', arr);
  return arr;
} // end parseLinks

//////////////////////////////////////////////////////////////////////////

// Extracts the plain text from an array of linked reacted components

const extractPlainTextFromParsedLinks = (array) =>
  array.map((element) =>
    element.props !== undefined ? element.props.children : element
  );
let createLinkUniqueNumber = 0;
function createLink([url, text]) {
  createLinkUniqueNumber++;
  return (
    <a
      href={url}
      key={`createLink-${url}-${text}-${createLinkUniqueNumber}`}
      target="Prism"
    >
      {text}
    </a>
  );
} // end extractPlainTextFromParsedLinks

// function createLinkACS([url]) {
//   return (
//     <a href={url} target="Prism">
//       {/* {url.split("/")[2] + "..."} */}
//       {url.split("/").slice(0, -1).join("/") + "..."}
//     </a>
//   );
// } // end createLinkACS

// Not using these yet
// function createBold([text]) {
//   return <p style={{ fontWeight: "bold" }}>{text}</p>;
// } // end createBold

// function createItalic([text]) {
//   return <p style={{ fontStyle: "italic" }}>{text}</p>;
// } // end createItalic

function createHighlight([text]) {
  return (
    <span key={`highlight-${text}`} className="highlight">
      {text}
    </span>
  );
} // end createHighlight

function parseAllLinks(strs, regex, createElement, n) {
  if (!strs || strs.length === 0) throw new Error("No strings to parse");
  if (typeof strs === "string") strs = [strs]; //convert input string to array
  //need to modify this to work with jsx elements (ie. links right now aren't highlightable)
  const nestedArr = strs.map((
    str //parent array of array of strings and jsx elements
  ) =>
    typeof str === "string"
      ? createElement.name === "createHighlight"
        ? getHighlighElements(str, regex, createElement)
        : parseLinks(str, regex, createElement, n)
      : str
  );

  const retVal = [];
  for (const el of nestedArr) {
    if (Array.isArray(el)) {
      retVal.push(...el);
    } else {
      retVal.push(el);
    }
  }
  return retVal;
} // end parseAllLinks

/**
 * Try to parse author names inclosed in tag/markup
 * https://wiki.autodesk.com/pages/viewpage.action?spaceKey=~jensenl&title=Prism+Use+Cases+-+Authors
 * @param {Object} authors - the authors object to parse
 * @returns {Object} - a map of author names
 */
const htmlTagRegex = /<[^>]*>/g;
const parseAuthorsFromMarkupTags = (_author) => {
  if (_author.trim() !== "" && htmlTagRegex.test(_author)) {
    // console.log(
    //   "found markup tags",
    //   _author
    //     .replace(htmlTagRegex, ",")
    //     .split(",")
    //     .filter((_name) => _name.trim() !== "")
    //     .join(",")
    // );
    return _author
      .replace(htmlTagRegex, ",")
      .split(",")
      .filter((_name) => _name.trim() !== "")
      .join(", ");
  }
  return _author;
};

function getAuthorAvatars(authors, blankAvatarPath) {
  return parseAuthors(authors).map((author) => {
    const cleanAuthorName = removeLinkFromString(author);
    return imageResolution(
      cleanAuthorName + ".png",
      "./staff/",
      blankAvatarPath
    );
  });
} // end getAuthorAvatars

function removeLinkFromString(linkedString) {
  const excelLinkRegex = /=HYPERLINK\("(https?|chrome):\/\/[^\s$.?#].[^\s]*";"/;
  let cleanString = linkedString
    .replace(excelLinkRegex, "")
    .replace(/\[|\)|"/g, "");
  return cleanString;
} // end removeLinkFromString

const staffArray = JSON.parse(staffListJson);
function findAvatarNamesFromJson(input) {
  const names = input.split(splitFullnameRegex);
  let matches = names.filter((fullName) => staffArray.includes(fullName));
  // Try without the split
  if (matches.length === 0) {
    matches = staffArray.filter((fullName) => input.includes(fullName));
  }
  return matches;
} // end findAvatarNamesFromJson

// Given a string (typically from an 'Author' field), this returns an
// array of author names parsed from that string. (Eventually this will
// likely be used as a backup parser after the author string is scanned
// for known staff/company names.)
//
// Here are some examples of 'Author' field values seen in the wild.
// The more of these cases we can support, the better.
//
// -                                    <- blank (or whitespace)
// - Illuminas                          <- one-word name (e.g. a company name)
// - Michael Saunders                   <- single name
// - Lars Jensen, Adam Richardson       <- multiple names, comma-space delimiter
// - Jennifer Perchonok Elise Lockwood Peter Maxfield <- mailto links, with various delimiters
// - Emily Tsai Sara Kremer             <- no delimiter, plain text (not sure how this even works)
// - Michael Saunders; Ben Leduc-Mills  <- other delimiters
// - Ben Leduc-Mills                    <- hyphenated names
// - N/A                                <- names with special meanings
// - Kyle Shulman (Lisa Seaman consult)         <- some names in parens, with other stuff
// - AEX, led by Prophet (Bre Arder Consulting) <- ditto
// - Gabe Zentall, Lamin Mansaray consulting    <- other stuff, but no parens
// - Holly Redahan -vs- Holly Matthews                  <- aliases
// - Yedige Tlegenov -vs- Yedige Tlegenov               <- more aliases, no "starts with" match
// - Nicole E Carey -vs- Nicole E. Carey -vs- Nic Carey <- more aliases w/ varying punctuation
// - Christie McAllister, on behalf of Chrissy Charlton/ BMP team <- commentary in the name field
// - Christie McAllister, led by Adam Richardson and Shane Chase (Enigma Bureau) <-- ditto
// - Emerge Interactive, Christie McAllister        <- company names and staff anmes
// - Lisa Seaman, as part of Gateways POD           <- comma with other stuff
// - Joe Kappes, Emily Tsai, (Alix Cohen)           <- name in parens (with delimiter too)
// - Judy Bayne, Lars Jensen assisting              <- not always "consulting"
// - Adam Richardson (Enigma Bureau), Christie McAllister <- person (company), person
// - Christie McAllister documenting, Diane Li/ BMP team commissioned PwC <- wow
// - [names in URLs, e.g. wiki links, mailto]
//
// Bonus note: to see a record with many (23) authors, search for
// search for 'crowdsourced fabrication' within the 'octo' dataset.

const splitFullnameRegex = /\r\n|\r|, |; |\n/g;
function parseAuthors(authors) {
  const result = [];
  const shownAuthors = [];
  authors.forEach((author) => {
    let fullName = author.entry;
    if (fullName && fullName.trim() !== "") {
      fullName = fullName.trim();
      if (
        // Don't repeat the same author across multiple fields
        !shownAuthors.includes(fullName) &&
        // Skip special meaning strings - https://wiki.autodesk.com/pages/viewpage.action?spaceKey=~jensenl&title=Prism+Use+Cases+-+Authors
        fullName.match(/^NA|N\/A$/) === null
      ) {
        // Strip author name from HTML
        fullName = parseAuthorsFromMarkupTags(fullName);
        // Deals with some names in parens, with other stuff
        fullName = fullName
          .replace(/\s\(/g, ", ")
          .replace(/\)\s?/g, "")
          .replace(",, ", ", ");
        // Deals with stuff we want to remove from names
        fullName = fullName.replace(
          /consulting|assisting|documenting|consult|led by |on behalf of |as part of [^\s|,]+/gi,
          ""
        );
        // Deals with aliases
        fullName = fullName.replace(/ OR|and /gi, ", ");
        //if we have ", " inside string, it will be split off of it; if not, array of single string
        //new lines;split by carriage return \r or line feed \n
        let names = fullName.split(splitFullnameRegex);
        if (names.length > 1) {
          for (const _name of names) {
            const cleanName = _name.trim();
            result.push(cleanName);
            shownAuthors.push(cleanName);
          }
        } else {
          // Try to look up names in the available avatars
          names = findAvatarNamesFromJson(fullName);
          if (names.length > 0) {
            for (const _name of names) {
              const cleanName = _name.trim();
              result.push(cleanName);
              shownAuthors.push(cleanName);
            }
          } else {
            result.push(fullName);
            shownAuthors.push(fullName);
          }
        }
      }
    }
  });

  return result;
} // end parseAuthors

// Given a string representing a date (typically from a 'Date' field that
// might or might not be well-formed), return a normalized date string in
// YYYY_MM_DD format, suitable for alphanumeric sorting. Zeroes are used
// for portions of the date that cannot be reliably inferred. Typical cases:
//
// "July 4, 2022"   -> "2022_07_04"  normal case
// "Jul 2022"       -> "2022_07_00"  unspecified day, abbrev month
// "2022"           -> "2022_00_00"  unspecified day and month
// ""               -> "0000_00_00"  unspecified day and month and year
// "Summer 2022"    -> "2022_06_00"  use first month in season/range
// "FY22 Q3"        -> "2021_08_00"  FY starts Feb 1 of prior year
// 09/22/2022       -> "2022_09_22"  assume USA day/month order where ambiguous
//
// Here are some examples of 'Date' field values seen in the wild.
// The more of these cases we can support, the better.
//
// - July 9, 2021 (pilot ends, share-out results)   <- parenthesized commentary
// -                           <- empty (unknown)
// - 11/15/19                  <- slashes (ambiguous day vs month)
// - June 10th                 <- suffix on date
// - 05 Nov 2020               <- leading zero
// - Jan 5 - 19, 2020          <- day range
// - Jan-Feb 2022              <- month range
// - Nov 2021-Apr 2022         <- spans a year boundary
// - FY22 Q4                   <- fiscal notation
// - Q3FY2021                  <- ditto (same author btw)
// - FY22                      <- ditto
// - Aug - October 2021        <- mixed abbreviations
// - May-Sept 2021             <- four-letter abbreviation
// - FY22 Q3 (August-October)  <- restated in parens
// - FY22Q3, August 2021       <- restated more precisely after comma
// - Ongoing                   <- indefinite
// - Jan 2020 and ongoing      <- commentary that makes it indefinite
// - TBD                       <- unknown
// - ON HOLD                   <- unknown?
// - 09/22/2020 (TBD)          <- unknown?
// - November 11-20, 2020      <- date precision
// - Summer 2020               <- seasonal (several in HIVE)
// - Summer/Fall 2020          <- multi-seasonal (one in DPE)
// - YEAR=2019; DATE COMPLETED=5/14/19 <-- two different fields (typical ACS)
// - July 2020 (put on hold); Nov 23 - Dec 8 2020 (completed) <- a whole status report

const toIsoDateFormat = (date) => date.toISOString().split("T")[0];

const get4DigitsYear = (yearString) =>
  yearString.length === 2 ? `20${yearString}` : yearString;

const getDateWithQuarter = (quarterString, year4Digit) => {
  let result;
  switch (parseInt(quarterString)) {
    case 1:
      result = `01 February ${year4Digit}`;
      break;
    case 2:
      result = `01 May ${year4Digit}`;
      break;
    case 3:
      result = `01 August ${year4Digit}`;
      break;
    default:
      result = `01 November ${year4Digit}`;
  }
  return result;
};

function parseDate(fieldValue) {
  // YYYY_MM_DD?
  const underscoreDateRegex = /([0-9]{4})_([0-9]{2})_([0-9]{2})/;
  let matches = fieldValue.match(underscoreDateRegex);
  if (
    matches !== null &&
    matches.length === 4 &&
    matches[1] !== undefined &&
    matches[2] !== undefined &&
    matches[3] !== undefined
  ) {
    const dateTime = new Date(
      matches[1], // year
      parseInt(matches[2]) - 1, // month (zero indexed)
      matches[3] // day
    );
    return toIsoDateFormat(dateTime);
  }
  // FY + Quarter?
  const fiscalYearQuarterRegex = /^FY([0-9]{2,4})\s?Q([1-4]{1})?/i;
  matches = fieldValue.match(fiscalYearQuarterRegex);
  if (matches !== null) {
    if (
      matches.length === 3 &&
      matches[1] !== undefined &&
      matches[2] !== undefined
    ) {
      // Contains quarter as well
      const year4Digit = get4DigitsYear(matches[1]);
      fieldValue = getDateWithQuarter(matches[2], year4Digit);
    } else if (matches.length === 2 && matches[1] !== undefined) {
      // Just year
      fieldValue = `01 January ${get4DigitsYear(matches[1])}`;
    }
  } else {
    // Quarter + FY
    const quarterFiscalYearRegex = /^(Q[1-4]{1})\s?FY\s?([0-9]{2,4})/i;
    matches = fieldValue.match(quarterFiscalYearRegex);
    if (
      matches !== null &&
      matches.length === 3 &&
      matches[1] !== undefined &&
      matches[2] !== undefined
    ) {
      const year4Digit = get4DigitsYear(matches[2]);
      fieldValue = getDateWithQuarter(matches[1], year4Digit);
    }
  }

  // Summer YY or YYYY?
  const seasonYearRegex = /(winter|spring|summer|fall)\s?([0-9]{2,4})/i;
  matches = fieldValue.match(seasonYearRegex);
  if (
    matches !== null &&
    matches.length === 3 &&
    matches[1] !== undefined &&
    matches[2] !== undefined
  ) {
    const year4Digit = matches[2].length === 2 ? `20${matches[2]}` : matches[2];
    switch (matches[1].toLowerCase()) {
      case "winter":
        fieldValue = `01 December ${year4Digit}`;
        break;
      case "spring":
        fieldValue = `01 March ${year4Digit}`;
        break;
      case "fall":
        fieldValue = `01 September ${year4Digit}`;
        break;
      default:
        fieldValue = `01 June ${year4Digit}`;
        break;
    }
  }

  // Use JS core date parse to parse date string into unix timestamp
  const date = new Date(fieldValue);
  return date.toString() === "Invalid Date"
    ? "0000-00-00"
    : toIsoDateFormat(date);
} // end parseDate

//detect hyperlinks in DPE, ACS, ALEX datasets
function superParser(searchMe, searchString, searchType) {
  //take in curr search term and create parseAllLinks with that search term

  if (!searchMe || searchMe.length === 0) return searchMe;

  // Deals with slack & Excel links with missing parts
  const bogusUrlRegexes = [
    RegExp(`<(.*?)\\|(.*?)>`),
    RegExp(`=HYPERLINK\\(""?(.*?)""?[;|,]+""?(.*?)""?\\)`),
  ];
  for (const bogusRegex of bogusUrlRegexes) {
    const matches = searchMe.match(bogusRegex);
    if (matches !== null) {
      const url = matches[1];
      const text = matches[2];
      //console.log('bogus match', {searchMe:searchMe, matches:matches, url:url, text:text});
      if (
        (url === undefined || url.trim() === "") &&
        (text === undefined || text.trim() === "")
      ) {
        return [""];
      } else if (
        (url === undefined || url.trim() === "") &&
        text !== undefined &&
        text.trim() !== ""
      ) {
        return [text];
      }
    }
  }

  //match string to markup vs url?
  const urlRegex =
    "https?:\\/\\/(?:www\\.)?[-a-zA-Z0-9@:%._\\+~#=]{1,256}\\.[a-zA-Z0-9]{1,6}\\b(?:[-a-zA-Z0-9@:%_\\+.~#?&//=]*)";

  // Links of this form: =HYPERLINK("url";"text") where:
  // - there can be one or two double quote chars at a time
  // - separator can be semicolon or comma
  // - separator can have spaces before or after
  // These occur in MS apps and are exported by Confluence wiki.
  const DPEregex = new RegExp(
    `=HYPERLINK\\(""?(${urlRegex})?""? *[;|,] *""?(.*?)""?\\)`
  );
  searchMe = parseAllLinks(
    searchMe,
    DPEregex,
    ([url, text]) =>
      createLink([url, highlightText(text, searchString, searchType)]),
    3
  );

  // Links of this form: <url|text>
  // These are part of Slack's "mrkdown" formatting.
  // See https://api.slack.com/reference/surfaces/formatting
  // "+ = 1 or more

  const ALEXregex = new RegExp(`<(${urlRegex})\\|(.*?)>`);
  // const ALEXregex = new RegExp(`<(${urlRegex})\\|(.*)(>*)`);
  searchMe = parseAllLinks(
    searchMe,
    ALEXregex,
    ([url, text]) =>
      createLink([url, highlightText(text, searchString, searchType)]),
    3
  );

  // Links of this form: url
  const ACSregex = new RegExp(`(${urlRegex})`);
  searchMe = parseAllLinks(
    searchMe,
    ACSregex,
    ([url, text]) =>
      createLink([
        url,
        highlightText(
          url
            .split("/")
            .slice(0, -1)
            .join("/") + "...",
          searchString,
          searchType
        ),
      ]),
    2
  );

  //bold text (not working)
  // const boldRegex = new RegExp(`/\*(.+?)\*/g`);
  // searchMe = parseAllLinks(searchMe, boldRegex, createBold, 2);

  // //italicize text (not working)
  // const italicizeRegex = new RegExp(`/\_(.+?)\_/g`);
  // searchMe = parseAllLinks(searchMe, italicizeRegex, createItalic, 2);
  searchMe = highlightText(searchMe, searchString, searchType);
  return searchMe;
} // end superParser

const highlightText = (searchMe, searchString, searchType) => {
  let searchMeClone = Array.isArray(searchMe)
    ? [...searchMe]
    : typeof searchMe === "string"
    ? searchMe.split("").join("")
    : "";
  //console.log('highlightText('+searchMe+', '+searchString+', '+searchType+')');
  if (searchString) {
    try {
      const regex = getMatchRegex(searchString, searchType);
      // if we get a blank string as regex, bail out
      if (regex === "") {
        return searchMe;
      }

      const regexes = [];
      // WW with multiple words will try to match different words combinations
      // so try to highlight each combo word
      if (
        containsQuotedWords(searchString) === false &&
        searchType === SEARCH_OPTION_WHOLE_WORD
      ) {
        for (const keyword of searchString.split(/\s/)) {
          regexes.push(`\\b(${keyword})\\b`);
        }
      } else {
        regexes.push(regex);
      }

      for (let regex of regexes) {
        //regex for search string is the string itself
        const highlightRegex = new RegExp(regex, "gi");

        //console.log('highlightRegex', highlightRegex, searchMe);

        searchMeClone = parseAllLinks(
          searchMeClone,
          highlightRegex,
          createHighlight,
          2
        );
      }
    } catch (e) {
      console.error("could not highlight:", searchString, e);
    }
  }
  return searchMeClone;
}; // end highlightText

//////////////////////////////////////////////////////////////////////////

export {
  getHTMLForDatasetCategories,
  getHTMLForListItems,
  getFirstDataset,
  datasetMetadataFromId,
  superParser,
  createLink,
  arrayIncludesCaseInsensitive,
  valueOfFirstField,
  applyLinkFields,
  getDatasetsforCategory,
  getFirstDatasetCategory,
  findDatasetIndex,
  findDatasetCategoryIndex,
  isValidCollection,
  isValidDataset,
  getFirstCollectionWithDatasetId,
  applyLinkToFields,
  applyRemoveHeaderEndings,
  stringWithoutEndings,
  getAuthorAvatars,
  parseAuthors,
  parseDate,
  htmlTagRegex,
  highlightText,
  extractPlainTextFromParsedLinks,
};
//when exporting named exports, need to import it with {}