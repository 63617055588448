import create from 'zustand';

// Zustand store implementation for CSV data collection

const useStore = create(set => ({
	tables: [],
  	addTable: (key, table) => 
		set(state => ({
			tables: [
				{
					key: key,
					data: table
				},
				...state.tables
			]
		}))
	,
  	removeTable: key => 
		set(state => ({
			currentTable: state.tables.filter(table => table.key !== key)
		}))
	,
  	updateTable: (key, table) =>
    	set(state => ({
      		tables: state.tables.map((item, itemKey) => {
        		if (key === itemKey) {
					return {
						...item,
						table
					};
				} else {
					return item;
				}
      		})
    	}))
}));

export const useDatasetTableStore = useStore;