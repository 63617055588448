import React from "react";

function MainList({ dataset, datasetList }) {
  const cardView = dataset.cardView;
  return dataset.hasItemDetail ? (
    <div key={dataset.id} className="mainListWithDetail">
      <ul key={`ul-${dataset.id}`} className={`${cardView && "cardView"}`}>{datasetList}</ul>
    </div>
  ) : (
    <div key={dataset.id} className="mainList">
      <ul key={`ul-${dataset.id}`} className={`${cardView && "cardView"}`}>{datasetList}</ul>
    </div>
  );
}

export default MainList;
